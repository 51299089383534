import React, { useEffect } from 'react';
import hive from "./index"
import { project } from "./project/project"
import { useState } from "@hookstate/core"

export function TourEmbed(props: any) {
    return <div style={{
        width: "100%",
        height: "100%",
    }}>
        <div style={{
            width: "100%",
            height: "100%",
            overflow: "hidden"
        }}>
            <iframe src={`https://www.theasys.io/viewer/${props.tourId}`} scrolling="no" allow="vr;gyroscope;accelerometer"
                style={{
                    width: '100%',
                    height: "100%",
                    border: "none"
                }}>
            </iframe>
        </div>
    </div>
}

function Tour() {
    const { width, height, mainColor, tour, activeScene } = useState(hive.state)

    return <div style={{
        background: "white",
        position: "absolute",
        height: height.get() + 'px',
        width: "100vw",
        zIndex: 2,
        display: "flex",
    }}>
        {project.tourDisclaimer && hive.getSceneById(activeScene.get()).type && <>
            <div style={{
                position: "absolute",
                left: width.get() * 0.2,
                top: height.get() * 0.020,
                color: `#${mainColor.get()}`,
                fontSize: height.get() * 0.023,
            }}>
                {activeScene.get() && <>
                    Visite virtuelle d'un logement type T{hive.getSceneById(activeScene.get()).type}
                </>}
            </div>
        </>}
        <TourEmbed tourId={tour.get()}></TourEmbed>
    </div>
}

export default Tour;